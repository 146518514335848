import { ParsedUrlQuery } from 'querystring';
import { uuidv4 } from '@sortlist-frontend/utils';

import { UpdatedBriefing } from '_pages/api/proxy/briefing-ai/update-briefing-ai';

import { AiBriefingForm, AiBriefingProps } from './types';

export enum AiBriefingStep {
  PROJECT_DESCRIPTION = 'projectDescription',
  AI_SUGGESTIONS = 'aiSuggestions',
  LOCATION = 'location',
  SKILLS = 'projectSkills',
  LANGUAGES = 'languages',
  COMPANY_SIZE = 'companySize',
  INDUSTRY = 'industry',
  PERSONA = 'persona',
  BUDGET = 'budget',
  SUMMARY = 'summary',
  MEMBER = 'member',
  PHONE = 'phone',
  PHONE_VALIDATION = 'phoneValidation',
}

export const getDefaultValues = (props: AiBriefingProps, queryParams?: ParsedUrlQuery): AiBriefingForm => {
  const { expertise, locale = 'en', currency = 'EUR', address, placeId, sources, projectSkills, modifiers } = props;

  return {
    languages: [locale],
    expertise,
    ...(address != null && placeId != null && { location: { address, placeId } }),
    context: {
      address: address ?? '',
      currency,
      expertise,
      iso31661: '',
      locale,
      page: '',
      placeId: placeId ?? '',
      projectSkills,
      source: sources ?? {},
      modifiers,
    },
    projectUuid: (queryParams?.project_uuid as string) ?? uuidv4(),
  };
};

export const fillBriefingForm = (
  reset: (values: Partial<AiBriefingForm>, options: { keepValues: boolean }) => void,
  briefing: UpdatedBriefing,
) => {
  const {
    budget,
    companyDomainUrl,
    companyIndustry,
    companyLogo,
    companyName,
    companySize,
    expertiseId,
    goalsAndNeeds,
    languages,
    location,
    monthlyBudget,
    personaOptions,
    skills,
    requiredSectorExperience,
    strictOfficeLocation,
  } = briefing;

  const { address, placeId } = location ?? {};

  reset(
    {
      ...(budget != null && { budget }),
      budgetExtras: Boolean(monthlyBudget) ? ['monthly'] : [],
      company: {
        domain: companyDomainUrl,
        logo: companyLogo,
        name: companyName,
      },
      ...(companySize != null && { companySize }),
      expertise: expertiseId,
      extraComment: goalsAndNeeds,
      ...(companyIndustry != null && { industry: companyIndustry }),
      initialDescription: goalsAndNeeds,
      ...(languages != null && { languages }),
      ...(address != null && placeId != null && { location: { address, placeId } }),
      ...(personaOptions != null && { persona: personaOptions }),
      ...(skills != null && { projectSkills: skills }),
      requiredSectorExperience,
      strictOfficeLocation,
    },
    { keepValues: true },
  );
};

import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { NormalBriefingStep } from '@sortlist-frontend/shared-components';
import { useQueryParams, useWindowMessage, WindowMessageParams } from '@sortlist-frontend/utils';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { BriefingCTA } from '_components/Briefing/types';
import { useNavigationContext } from '_core/context/navigation-context';
import { usePublicAppContext } from '_core/context/public-app-context';
import { getSourceObject } from '_core/tracking/entry-url-params-storer';

import { AiBriefingProps } from '../AiBriefing/types';
import { AiBriefingStep } from '../AiBriefing/utils';
import { NormalBriefingProps } from './types';
import { computeInitialStep } from './utils';

const NormalBriefing = dynamic<NormalBriefingProps>(
  () => import('_components/Briefing/versions/NormalBriefing/NormalBriefing').then((mod) => mod.NormalBriefing),
  {
    ssr: false,
  },
);
const AiBriefing = dynamic<AiBriefingProps>(
  () => import('_components/Briefing/versions/AiBriefing/AiBriefing').then((mod) => mod.AiBriefing),
  {
    ssr: false,
  },
);

type Data = { cta?: BriefingCTA; expertiseId?: number; results?: string };

export const OPEN_BRIEFING = 'open-briefing';
export const FINISH_BRIEFING = 'finish-briefing';
export const CLOSE_BRIEFING = 'close-briefing';

type Action = typeof OPEN_BRIEFING | typeof CLOSE_BRIEFING | typeof FINISH_BRIEFING;

export const useBriefingMessage = (params?: WindowMessageParams<Action, Data>) =>
  useWindowMessage<Action, Data>(params);

export const BriefingDialog = () => {
  const [cta, setCta] = useState<BriefingCTA | undefined>();
  const [expertise, setExpertise] = useState<number | undefined>();

  const { isActive, triggerEvent: triggerBriefingExperiment } = useIsFeatureActive('ai-briefing');

  const { query } = useNavigationContext();
  const {
    urlParamsObject: { briefing: briefingParam },
    setUrlParams,
  } = useQueryParams<{ briefing: 'open'; step: NormalBriefingStep | AiBriefingStep }>();

  const { domainInfo, briefingOptions, locale } = usePublicAppContext();
  const {
    expertise: expertiseOption,
    skills,
    placeId,
    address,
    iso31661,
    page,
    skillObjects,
    modifiers,
  } = briefingOptions ?? {};

  const currency = domainInfo?.getCurrency() ?? 'EUR';
  const defaultIso31661 = domainInfo?.getIso31661();
  const { placeId: defaultPlaceId, address: defaultAddress } = domainInfo?.getLocation(locale) ?? {};

  // Next doesn't handle regional locales yet.. The only one we handle at the moment is nl-BE.
  // To make sure the project are posted correctly and handled by the right person, we need this trick.
  const regionalizedLocale = domainInfo?.getIso31661() === 'BE' && locale === 'nl' ? 'nl-BE' : locale;

  const briefingProps = {
    page: page,
    cta: cta,
    address: address ?? defaultAddress,
    placeId: placeId ?? defaultPlaceId,
    expertise: expertise ?? expertiseOption,
    locale: regionalizedLocale ?? 'en',
    currency: currency ?? undefined,
    iso31661: iso31661 ?? defaultIso31661 ?? undefined,
    skills: skills,
    projectSkills: skillObjects,
    open: briefingParam === 'open',
    onClose: () => setUrlParams({ briefing: undefined }),
    queryParams: query,
    modifiers,
  };

  const { sendMessage, sendMessageToParent } = useBriefingMessage({
    onReceiveMessage: (action, data) => {
      if (action === OPEN_BRIEFING) {
        const hasExpertise = data.expertiseId != null;
        setUrlParams({
          briefing: 'open',
          step: isActive ? AiBriefingStep.PROJECT_DESCRIPTION : computeInitialStep({ hasExpertise }),
        });
        setCta(data.cta);
        setExpertise(hasExpertise ? Number(data?.expertiseId) : undefined);
        triggerBriefingExperiment();
      }
      if (action === CLOSE_BRIEFING) {
        setUrlParams({ briefing: undefined });
        setCta(undefined);
        setExpertise(undefined);
      }
    },
    sendToIframeId: 'directory-actions',
  });

  if (cta == null) return;

  if (briefingParam === 'open')
    return isActive ? (
      <AiBriefing
        {...briefingProps}
        sources={getSourceObject()}
        cta={cta}
        onClose={() => {
          sendMessage(CLOSE_BRIEFING);
          sendMessageToParent(CLOSE_BRIEFING);
          setUrlParams({ briefing: undefined, step: undefined });
        }}
      />
    ) : (
      <NormalBriefing
        {...briefingProps}
        sources={getSourceObject()}
        cta={cta}
        onClose={() => {
          sendMessage(CLOSE_BRIEFING);
          sendMessageToParent(CLOSE_BRIEFING);
          setUrlParams({ briefing: undefined, step: undefined });
        }}
      />
    );
};

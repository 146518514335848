import { ParsedUrlQuery } from 'querystring';
import type {
  Location,
  NormalBriefingFormValues,
  NormalBriefingStep,
  NormalBriefingStepConfig,
  NormalBriefingSteps,
  SchemaVersionType,
} from '@sortlist-frontend/shared-components';
import { TFunction, Trans } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyArray, isNonEmptyString } from '@sortlist-frontend/utils';
import type { SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { trackBriefingCanceled, trackBriefingClosed, trackBriefingContinued } from '_components/Briefing/trackers';
import { SERVICE_INFO } from '_components/form/budgetV2/constants';
import { findOption as findPersonaOption } from '_components/form/PersonaSelector/utils';
import { getSourceObject } from '_core/tracking/entry-url-params-storer';
import { NormalBriefingPayload } from '_pages/api/proxy/project/normal-briefing';
import { BriefingScreenContent } from '_types/generic';

import type { ExtendedFormValues, NormalBriefingProps, NormalBriefingVariables, SavedIds } from './types';

export const getDefaultValues = (props: NormalBriefingProps): NormalBriefingFormValues => {
  const { expertise, locale = 'en', projectSkills, address, placeId } = props;
  return {
    languages: [locale],
    expertise,
    projectSkills: projectSkills || [],
    ...(address && placeId && { location: { address, placeId } }),
    companySize: [],
  };
};

export const ORDER_ID = '_rtb_order_id';

export const getMemberArgs = (props: ExtendedFormValues, t: TFunction) => {
  const { firstName, lastName, email, terms, phone, phoneIso31661, locale, persona } = props;
  const personaOption = persona && findPersonaOption(persona, t);

  return {
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...(locale && { locale }),
    ...(email && { email }),
    ...(terms && { terms }),
    ...(phone && { phone }),
    ...(phoneIso31661 && { phoneIso31661 }),
    ...(personaOption && { jobTitle: persona.tag === 'other' ? persona.value : personaOption?.label }),
  };
};

export const getBriefingSchema = (props?: { isReferral?: boolean; isAiBriefing?: boolean }): SchemaVersionType => {
  const { isReferral, isAiBriefing } = props ?? {};
  if (isReferral) return 'vReferral2.0';
  if (isAiBriefing) return 'vLight6.2-AI';
  return 'vLight6.2';
};

export const getLocationArgs = (location?: Location, countryCode?: string) => {
  const { placeId, types, coordinates, address, polygon } = location || {};

  return {
    address,
    placeId,
    country: countryCode,
    ...(types && { types }),
    ...(coordinates && { coordinates }),
    ...(polygon && { polygon }),
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const getBriefingArgs = (props: ExtendedFormValues, isReferral: boolean) => {
  const {
    blobIds,
    budget,
    budgetExtras,
    companySize,
    company,
    currency,
    expertise,
    extraComment,
    industry,
    locale: tempLocale,
    languages,
    persona,
    projectSkills,
    requiredSectorExperience,
    strictOfficeLocation,
    iso31661,
    location,
  } = props;
  const { country, placeId, address } = { ...location };
  const countryCode = country || iso31661;
  const locale = isNonEmptyString(tempLocale) ? tempLocale : 'en';

  return {
    locale,
    languages,
    ...(isReferral && { purpose: { tag: 'hire', value: '' } }),
    ...(expertise && { expertise }),
    ...(persona && { persona }),
    ...(extraComment && { extraComment }),
    ...(blobIds && { blobIds }),
    ...(budget && { budget }),
    ...(budgetExtras && { budgetExtras }),
    ...(companySize && { companySize }),
    ...(currency && { currency }),
    ...(countryCode && { iso31661: countryCode }),
    ...(company && { company }),
    ...(industry && { industry }),
    ...(isNonEmptyArray(projectSkills) && { projectSkills }),
    ...(requiredSectorExperience && { requiredSectorExperience }),
    ...(strictOfficeLocation && { strictOfficeLocation }),
    ...((placeId || address) && {
      location: getLocationArgs(location, iso31661),
    }),
  };
};

export const getTitles = (isReferral: boolean, t: TFunction) => {
  return isReferral
    ? {
        expertiseTitle: (
          <Trans i18nKey={'briefing:referral.expertise.questionv2'}>
            What <span className="text-primary-500">service</span> does your client need?
          </Trans>
        ),
        budgetTitle: (
          <Trans i18nKey={'briefing:referral.budget.question_v2'}>
            What is the <span className="text-primary-500">budget</span> range of your client?
          </Trans>
        ),
        companyTitle: (
          <Trans i18nKey={'briefing:referral.company.question_v4'}>
            What is the name of your client's <span className="text-primary-500">company</span>?
          </Trans>
        ),
        companySizeTitle: (
          <Trans i18nKey={'briefing:referral.companySize.question_v3'}>
            What is your client's company <span className="text-primary-500">size</span>?
          </Trans>
        ),
        industryTitle: (
          <Trans i18nKey={'briefing:referral.industry.questionv2'}>
            In which <span className="text-primary-500">industry</span> does your client work?
          </Trans>
        ),
        personaTitle: (
          <Trans i18nKey={'briefing:referral.persona.questionv2'}>
            What is your client's <span className="text-primary-500">job position</span>?
          </Trans>
        ),
        agencySizeTitle: (
          <Trans i18nKey={'briefing:referral.size.questionv2'}>
            Which <span className="text-primary-500">size</span> of agency would your client prefer?
          </Trans>
        ),
      }
    : {
        expertiseTitle: (
          <Trans i18nKey={'briefing:expertise.questionv2'}>
            What <span className="text-primary-500">service</span> do you need?
          </Trans>
        ),
        budgetTitle: (
          <Trans i18nKey={'briefing:budget.question_v2'}>
            What <span className="text-primary-500">budget</span> range would be comfortable for you?
          </Trans>
        ),
        companyTitle: (
          <Trans i18nKey={'briefing:company.question_v4'}>
            What is your <span className="text-primary-500">company</span> name?
          </Trans>
        ),
        companySizeTitle: (
          <Trans i18nKey={'briefing:companySize.question_v3'}>
            What is your company <span className="text-primary-500">size</span>?
          </Trans>
        ),
        industryTitle: (
          <Trans i18nKey={'briefing:industry.questionv2'}>
            In which <span className="text-primary-500">industry</span> do you work?
          </Trans>
        ),
        personaTitle: (
          <Trans i18nKey={'briefing:persona.questionv2'}>
            What's your <span className="text-primary-500">job position</span>?
          </Trans>
        ),
        agencySizeTitle: (
          <Trans i18nKey={'briefing:agencySize.questionv2'}>
            Which <span className="text-primary-500">size</span> of agency would you prefer?
          </Trans>
        ),
      };
};

export const getCreateVariables = (
  props: ExtendedFormValues,
  t: TFunction,
  isReferral = false,
): NormalBriefingVariables => {
  const { sources: sourceArgs = getSourceObject(), projectUuid } = props;

  return {
    projectUuid: projectUuid,
    memberUuid: uuidv4(),
    projectArgs: { confidential: true },
    briefingSchema: `urn:sortlist:project:briefing:${getBriefingSchema({ isReferral })}`,
    memberArgs: getMemberArgs(props, t),
    sourceArgs,
    briefingArgs: getBriefingArgs(props, isReferral),
  };
};

export const getUpdateMemberVariables = (props: ExtendedFormValues & { encodedProjectUuid: string }, t: TFunction) => {
  const { encodedProjectUuid, memberId } = props;

  return {
    encodedProjectUuid,
    memberId,
    memberArgs: getMemberArgs(props, t),
  };
};

export const STEPS: NormalBriefingSteps = [
  {
    name: 'expertise',
    showHeader: true,
    showActions: true,
    fields: ['expertise'],
    position: 0,
    overflowAuto: true,
    remainingAgencies: [549],
  },
  {
    name: 'projectSkills',
    showHeader: true,
    showActions: true,
    fields: ['projectSkills'],
    position: 1,
    overflowAuto: true,
    remainingAgencies: [549],
  },
  {
    name: 'location',
    showHeader: true,
    showActions: true,
    fields: ['location'],
    position: 2,
    overflowAuto: true,
    remainingAgencies: [549, 207],
  },
  {
    name: 'languages',
    showHeader: true,
    showActions: true,
    fields: ['languages'],
    position: 3,
    overflowAuto: true,
    remainingAgencies: [207, 137],
  },
  {
    name: 'company',
    showHeader: true,
    showActions: true,
    fields: ['company'],
    position: 4,
    overflowAuto: true,
    remainingAgencies: [90],
  },
  {
    name: 'companySize',
    showHeader: true,
    showActions: true,
    fields: ['companySize'],
    position: 5,
    overflowAuto: true,
    remainingAgencies: [90, 57],
  },
  {
    name: 'industry',
    showHeader: true,
    showActions: true,
    fields: ['industry'],
    position: 6,
    overflowAuto: true,
    remainingAgencies: [57, 28],
  },
  {
    name: 'persona',
    showHeader: true,
    showActions: true,
    fields: ['persona'],
    position: 7,
    overflowAuto: true,
    remainingAgencies: [28],
  },
  {
    name: 'budget',
    showHeader: true,
    showActions: true,
    fields: ['budget'],
    position: 8,
    overflowAuto: true,
    remainingAgencies: [28, 6],
  },
  {
    name: 'extraComment',
    showHeader: true,
    showActions: true,
    fields: ['extraComment'],
    position: 9,
    overflowAuto: true,
    remainingAgencies: [6],
  },
  {
    name: 'matching',
    showHeader: false,
    showActions: false,
    position: 10,
    bgColor: 'bg-neutral-100',
    overflowAuto: false,
  },
  {
    name: 'member',
    showHeader: true,
    isCloseButtonHidden: true,
    showActions: true,
    fields: ['email', 'firstName', 'lastName', 'terms'],
    position: 11,
    overflowAuto: true,
    remainingAgencies: [6],
  },
  {
    name: 'phone',
    showHeader: true,
    isCloseButtonHidden: true,
    showActions: true,
    fields: ['phone'],
    position: 12,
    overflowAuto: true,
    remainingAgencies: [6],
  },
  {
    name: 'phoneValidation',
    showHeader: true,
    isCloseButtonHidden: true,
    showActions: true,
    fields: [],
    position: 13,
    overflowAuto: true,
    remainingAgencies: [6],
  },
  {
    name: 'summary',
    showHeader: true,
    isCloseButtonHidden: true,
    showActions: false,
    fields: [],
    position: 14,
    overflowAuto: true,
    remainingAgencies: [6],
  },
];

export const findStep = (index: number): NormalBriefingStepConfig => {
  return STEPS.find((step) => step.position === index) || STEPS[0];
};

export const findStepByName = (name: string): NormalBriefingStepConfig => {
  return STEPS.find((step) => step.name === name) || STEPS[0];
};

export const endClosing = (
  activeStep: NormalBriefingStep,
  setModalContent: (value: SetStateAction<BriefingScreenContent>) => void,
  onClose: () => void,
  persona?: string,
  projectUuid?: string,
) => {
  trackBriefingCanceled({ step: activeStep, persona, projectUuid });
  onClose && onClose();
  setModalContent(BriefingScreenContent.STEPS);
};

export const startClosing = (
  activeStep: NormalBriefingStep,
  setModalContent: (value: SetStateAction<BriefingScreenContent>) => void,
  persona?: string,
  projectUuid?: string,
) => {
  trackBriefingClosed({ step: activeStep, persona, projectUuid });
  setModalContent(BriefingScreenContent.CLOSING);
};

export const cancelClosing = (
  activeStep: NormalBriefingStep,
  setModalContent: (value: SetStateAction<BriefingScreenContent>) => void,
  projectUuid?: string,
) => {
  setModalContent(BriefingScreenContent.STEPS);
  trackBriefingContinued({ step: activeStep, projectUuid });
};

export const getInfoMessageLabels = (t: TFunction) => {
  return {
    companySize: t('briefing:companySize.noCommitment_v2'),
    location: t('briefing:location.oneLocation'),
    budget: t('briefing:budget.noCommitment_v2'),
  };
};

export const getInfoMessage = (field: string, t: TFunction) => {
  const labels = getInfoMessageLabels(t);
  return labels[field as keyof typeof labels];
};

export const getSavedIds = (newBriefing?: NormalBriefingPayload, query?: ParsedUrlQuery): SavedIds => {
  const data = newBriefing?.response?.data;
  const project = data?.createProject;
  const member = data?.createProjectMember;
  const {
    encoded_project_uuid: queryToken = undefined,
    project_gql_id: queryEncodedProjectId = undefined,
    member_uuid: queryMemberUuid = undefined,
    project_id: queryProjectId = undefined,
    member_gql_id: queryEncodedMemberId = undefined,
  } = { ...query };

  return {
    token: queryToken || newBriefing?.token,
    encodedMemberId: queryEncodedMemberId || member?.id,
    encodedProjectId: queryEncodedProjectId || project?.id,
    memberUuid: queryMemberUuid || member?.uuid,
    projectId: queryProjectId || project?.projectId,
    projectUuid: project?.uuid,
  };
};

export const getAverageBudgetRangeForSelectedExpertise = (expertiseId?: number) => {
  if (!expertiseId) return;
  const expertiseTyped = expertiseId as unknown as keyof typeof SERVICE_INFO;
  const serviceInfo = SERVICE_INFO[expertiseTyped];
  if (!serviceInfo) return;
  return [serviceInfo?.lower, serviceInfo?.upper];
};

export const getProjectUuid = (queryParams: ParsedUrlQuery | undefined): string => {
  return (queryParams?.project_uuid as string) || uuidv4();
};

export const isCoreCountry = (iso31661: string, coreCountries: string[]) => {
  return coreCountries.includes(iso31661.toUpperCase());
};

export const computeInitialStep = (props: { hasExpertise: boolean }) => {
  const { hasExpertise } = props;
  if (hasExpertise) return 'projectSkills';
  return 'expertise';
};

import { RefObject, useEffect, useState } from 'react';

type Args<T> = {
  ref?: RefObject<T>;
  rootMargin?: string;
  onIsVisible?: () => void;
};

export function useIsVisible<T extends Element>(args: Args<T>): boolean {
  const { ref, rootMargin, onIsVisible } = args;
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState<boolean>(false);
  useEffect(() => {
    if (ref == null) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
        if (entry.isIntersecting) {
          onIsVisible?.();
        }
      },
      {
        rootMargin,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref != null]); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}

// This data was exported with the help of olivier@sortlist.com using: https://app.popsql.com/queries/-NLWf8LoofAt_KDJZ9Sj
export const SERVICE_INFO = {
  '58': {
    lower: 5000,
    ratio: 20.275988075776517,
    upper: 10000,
    average: 7965,
    works_count_in_range: 4217,
    works_count_in_service: 20798,
  },
  '60': {
    lower: 5000,
    ratio: 20.503296886737335,
    upper: 10000,
    average: 7859,
    works_count_in_range: 4913,
    works_count_in_service: 23962,
  },
  '61': {
    lower: 10000,
    ratio: 23.262642740619903,
    upper: 25000,
    average: 15729,
    works_count_in_range: 2852,
    works_count_in_service: 12260,
  },
  '62': {
    lower: 5000,
    ratio: 20.667067709377715,
    upper: 10000,
    average: 8035,
    works_count_in_range: 3092,
    works_count_in_service: 14961,
  },
  '63': {
    lower: 5000,
    ratio: 21.75786730807777,
    upper: 10000,
    average: 8081,
    works_count_in_range: 2171,
    works_count_in_service: 9978,
  },
  '65': {
    lower: 10000,
    ratio: 19.708329054123446,
    upper: 25000,
    average: 16064,
    works_count_in_range: 1919,
    works_count_in_service: 9737,
  },
  '66': {
    lower: 5000,
    ratio: 21.039294764267105,
    upper: 10000,
    average: 7804,
    works_count_in_range: 5895,
    works_count_in_service: 28019,
  },
  '67': {
    lower: 5000,
    ratio: 20.671215930404244,
    upper: 10000,
    average: 7881,
    works_count_in_range: 5180,
    works_count_in_service: 25059,
  },
  '68': {
    lower: 5000,
    ratio: 23.259788688626475,
    upper: 10000,
    average: 7781,
    works_count_in_range: 1497,
    works_count_in_service: 6436,
  },
  '69': {
    lower: 5000,
    ratio: 19.661579985466624,
    upper: 10000,
    average: 7706,
    works_count_in_range: 1894,
    works_count_in_service: 9633,
  },
  '70': {
    lower: 10000,
    ratio: 21.16599190283401,
    upper: 25000,
    average: 16011,
    works_count_in_range: 1307,
    works_count_in_service: 6175,
  },
  '72': {
    lower: 10000,
    ratio: 20.497707924034053,
    upper: 25000,
    average: 14937,
    works_count_in_range: 1565,
    works_count_in_service: 7635,
  },
  '74': {
    lower: 5000,
    ratio: 19.140597662537616,
    upper: 10000,
    average: 7828,
    works_count_in_range: 2735,
    works_count_in_service: 14289,
  },
  '75': {
    lower: 10000,
    ratio: 23.46193952033368,
    upper: 25000,
    average: 16181,
    works_count_in_range: 225,
    works_count_in_service: 959,
  },
  '77': {
    lower: 100000,
    ratio: 19.1644148825608,
    upper: 1000000,
    average: 354893,
    works_count_in_range: 922,
    works_count_in_service: 4811,
  },
  '79': {
    lower: 5000,
    ratio: 22.3089928347154,
    upper: 10000,
    average: 7943,
    works_count_in_range: 2771,
    works_count_in_service: 12421,
  },
  '83': {
    lower: 100000,
    ratio: 18.101386896221904,
    upper: 1000000,
    average: 340536,
    works_count_in_range: 757,
    works_count_in_service: 4182,
  },
  '84': {
    lower: 5000,
    ratio: 19.778281683043588,
    upper: 10000,
    average: 7951,
    works_count_in_range: 3140,
    works_count_in_service: 15876,
  },
  '85': {
    lower: 10000,
    ratio: 18.3038263849229,
    upper: 25000,
    average: 15563,
    works_count_in_range: 641,
    works_count_in_service: 3502,
  },
  '88': {
    lower: 5000,
    ratio: 20.543879351836996,
    upper: 10000,
    average: 8013,
    works_count_in_range: 2561,
    works_count_in_service: 12466,
  },
  '89': {
    lower: 10000,
    ratio: 19.834399698908545,
    upper: 25000,
    average: 15420,
    works_count_in_range: 527,
    works_count_in_service: 2657,
  },
  '91': {
    lower: 5000,
    ratio: 18.519467213114755,
    upper: 10000,
    average: 8099,
    works_count_in_range: 723,
    works_count_in_service: 3904,
  },
  '93': {
    lower: 10000,
    ratio: 18.49933155080214,
    upper: 25000,
    average: 15210,
    works_count_in_range: 1107,
    works_count_in_service: 5984,
  },
  '94': {
    lower: 10000,
    ratio: 19.785974499089253,
    upper: 25000,
    average: 14937,
    works_count_in_range: 869,
    works_count_in_service: 4392,
  },
  '95': {
    lower: 50000,
    ratio: 19.774483190645228,
    upper: 100000,
    average: 76407,
    works_count_in_range: 947,
    works_count_in_service: 4789,
  },
  '96': {
    lower: 50000,
    ratio: 20.928116469517743,
    upper: 100000,
    average: 76836,
    works_count_in_range: 460,
    works_count_in_service: 2198,
  },
};

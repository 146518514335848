import { TFunction } from '@sortlist-frontend/translation/ssr';

import type { FreeTextOption } from '_types/generic';

export type PersonaOption = {
  label: string;
  value: string;
};

export const getOptions = (t: TFunction): PersonaOption[] => [
  { value: 'biz_owner', label: t('common:personaSelector.bizOwner') },
  { value: 'marketing_director', label: t('common:personaSelector.marketingDirector') },
  { value: 'mktg_mgr', label: t('common:personaSelector.mktgMgr') },
  { value: 'mktg_cslt', label: t('common:personaSelector.mktgCslt') },
  { value: 'procurement_purchasing', label: t('common:personaSelector.procurementPurchasing') },
  { value: 'agency', label: t('common:personaSelector.agency') },
  { value: 'sales', label: t('common:personaSelector.sales') },
  { value: 'student', label: t('common:personaSelector.student') },
  { value: 'other', label: t('common:personaSelector.other') },
];

export const getPersonaOptions = (t: TFunction): FreeTextOption[] => {
  return getOptions(t).map((option) => ({ tag: option.value, value: option.label }));
};

export const exportPersona = (option: PersonaOption): FreeTextOption => {
  return { tag: option.value, value: '' };
};

export const findOption = (persona: FreeTextOption, t: TFunction): PersonaOption | undefined => {
  return getOptions(t).find(({ value }) => value === persona?.tag);
};

import { omitBy } from 'lodash-es';
import { usePathname, useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';

type UrlParamsObject = Record<string, string[] | string>;

interface Options<T> {
  defaultValue?: T;
}

interface SetUrlOptions {
  replace?: boolean;
}

export const useQueryParams = <T extends UrlParamsObject>(
  options: Options<T> = { defaultValue: {} as T },
): {
  setUrlParams: (value: Partial<T>, options?: SetUrlOptions) => void;
  urlParamsObject: T;
} => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const params = searchParams != null ? Object.fromEntries(searchParams) : {};
  const urlParamsObject: T = Object.keys(params).reduce((values, value) => {
    let parsedValue: string[] | string;
    if (params[value].includes(',')) {
      parsedValue = params[value].split(',');
    } else {
      parsedValue = params[value];
    }
    return { ...values, [value]: parsedValue };
  }, options.defaultValue as T);

  const setParamsFromObject = (newUrlParamsObject: Partial<T>, { replace }: SetUrlOptions = { replace: false }) => {
    const newSearchParams = new URLSearchParams(
      // Typescript doesn't allow the value of the param to be `string[]`,
      // however this gets encoded just fine for our purposes
      // @ts-expect-error see above
      omitBy({ ...urlParamsObject, ...newUrlParamsObject }, (v) => v == null),
    );
    const queryString = newSearchParams.toString();

    const delimiter = queryString.length > 0 ? '?' : '';

    if (searchParams?.toString() !== queryString) {
      if (replace) {
        router.replace(pathname + delimiter + queryString, undefined, { shallow: true });
      } else {
        router.push(pathname + delimiter + queryString, undefined, { shallow: true });
      }
    }
  };

  return {
    setUrlParams: setParamsFromObject,
    urlParamsObject,
  };
};
